<template>
  <div>
    <el-dialog
        title="Chuyển nhóm"
        :visible.sync="dialogVisible"
        width="450px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        @open = "handleOpen"
    >
      <el-form>
        <el-form-item label="Nhóm cũ">
          <span style="margin-left: 45px">{{nameOldGroup}}</span>
        </el-form-item>
        <el-form-item label="Chọn nhóm mới">
          <el-select v-model="selectNewGroup"  size="small" style="width: 255px" placeholder="Chọn nhóm mới">
            <el-option
                v-for="(item,index) in listNewGroup"
                :key="index"
                :label="item.nameGroup"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button type="success" @click="handleSwitchGroup"  size="medium" style="margin-left: 175px" icon="el-icon-circle-check" :disabled="selectNewGroup === ''" :loading="loadingSwitchGroup"> OK</el-button>
        <el-button type="danger" @click="closeDialog" size="medium" icon="el-icon-circle-close"> Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import StudentGroupService from "@/services/Finance_Kids_V2/StudentGroupService";

export default {
  computed:{
    ...mapState('studentGroupModule',['dataSearch','idKidList'])
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: ()=> false,
    },
  },
  data(){
    return{
      loadingSwitchGroup: false,
      kidsGroupList: [],
      nameOldGroup:"",
      listNewGroup: [],
      selectNewGroup: "",
      listStudentParams:{
        idKidList:[],
        idGroupOld:"",
        idGroupNew:"",
      }
    }
  },
  methods:{
    handleOpen(){
      StudentGroupService.getListKidsGroup().then((res) => {
        res&&res.data?this.kidsGroupList = res.data:this.kidsGroupList=[]
        for (let i = 0; i <  this.kidsGroupList.length; i++) {
          if (this.kidsGroupList[i].id === this.dataSearch.idGroup){
            this.nameOldGroup = this.kidsGroupList[i].nameGroup
          }
        }
      })
      StudentGroupService.getListKidsGroup().then((response) =>{
        this.listNewGroup = response.data
      })
    },
    handleSwitchGroup(){
      this.loadingSwitchGroup = true
      this.listStudentParams.idKidList = this.idKidList
      this.listStudentParams.idGroupOld = this.dataSearch.idGroup
      this.listStudentParams.idGroupNew = this.selectNewGroup
      StudentGroupService.changeGroupForListStudents(this.listStudentParams).then(() =>{
        this.$message({
          type: "success",
          message: "Chuyển nhóm thành công."
        })
        this.loadingSwitchGroup = false
        this.closeDialog()
      })
    },
    closeDialog() {
      this.loadingSwitchGroup = false
      this.selectNewGroup = ""
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
      this.$emit("dialog-close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 100px;
}
</style>