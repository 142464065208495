<template>
  <div>
    <el-dialog
        title="Thêm nhóm"
        :visible.sync="dialogVisible"
        width="450px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        @open = "handleOpen"
    >
      <el-form>
        <el-form-item label="Chọn thêm nhóm">
          <el-select v-model="listGroupSelect"
                     multiple
                     size="small"
                     style="width: 255px"
                     placeholder="Chọn nhóm"
                     class="button-left"
                     >
            <el-option
                v-for="(item,index) in listGroup"
                :key="index"
                :label="item.nameGroup"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button type="success" @click="handleAddToGroup"  size="medium" style="margin-left: 180px" icon="el-icon-circle-check" :loading="loadingAddToGroup" :disabled="listGroupSelect.length === 0"> OK</el-button>
        <el-button type="danger" @click="closeDialog" size="medium" icon="el-icon-circle-close"> Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
 import {mapState} from "vuex";
 import StudentGroupService from "@/services/Finance_Kids_V2/StudentGroupService";

 export default {
   computed:{
     ...mapState('studentGroupModule',['idKidList', 'dataSearch'])
   },
   props: {
     dialogVisible: {
       type: Boolean,
       default: ()=> false,
     },
   },
   data(){
     return{
       loadingAddToGroup: false,
       listGroup: [],
       listGroupSelect: [],
       studentsParam:{
         idKidList: [],
         idGroupList: [],
       }
     }
   },
   methods:{
     handleOpen(){
       StudentGroupService.getListKidsGroup().then((response) =>{
         this.listGroup = response.data
       })
     },
     handleAddToGroup(){
       this.loadingAddToGroup = true
       this.studentsParam.idKidList = this.idKidList.split(',').map(id => parseInt(id.trim(), 10));
       this.studentsParam.idGroupList = this.listGroupSelect
       StudentGroupService.addMultipleStudentInGroup(this.studentsParam).then(() =>{
         this.$message({
           type: 'success',
           message: 'Thêm thành công'
         })
         this.loadingAddToGroup = false
         this.closeDialog()
       })
     },
     closeDialog() {
       this.loadingAddToGroup = false
       this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
       this.listGroupSelect= []
       this.$emit("dialog-close", false);
     },
   }
 }
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 100px;
}
</style>