import { render, staticRenderFns } from "./StudentGroup1.vue?vue&type=template&id=6869888f&scoped=true"
import script from "./StudentGroup1.vue?vue&type=script&lang=js"
export * from "./StudentGroup1.vue?vue&type=script&lang=js"
import style0 from "./StudentGroup1.vue?vue&type=style&index=0&id=6869888f&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6869888f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6869888f')) {
      api.createRecord('6869888f', component.options)
    } else {
      api.reload('6869888f', component.options)
    }
    module.hot.accept("./StudentGroup1.vue?vue&type=template&id=6869888f&scoped=true", function () {
      api.rerender('6869888f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/student/StudentGroup1.vue"
export default component.exports