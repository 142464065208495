var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.componentKey }, [
    _c(
      "div",
      { staticClass: "table-content row-data" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingData,
                expression: "loadingData",
              },
            ],
            attrs: {
              "empty-text": _vm.$tableEmpty,
              "element-loading-text": _vm.$tableLoading,
              "max-height": _vm.$tableMaxHeight,
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(255,255,255, 0)",
              data: _vm.studentList,
              "highlight-current-row": "",
              "header-cell-style": _vm.tableHeaderColor,
              border: "",
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", align: "center", width: "50" },
            }),
            _c("el-table-column", {
              attrs: {
                type: "index",
                align: "center",
                label: "STT",
                width: "50",
              },
            }),
            _c("el-table-column", {
              attrs: { "min-width": "130", label: "Họ tên", prop: "fullName" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-tooltip", { attrs: { placement: "top" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v("Mã học sinh: " + _vm._s(scope.row.kidCode)),
                            _c("br"),
                            _vm._v(
                              "Bố/Mẹ: " +
                                _vm._s(scope.row.fatherPhone) +
                                " - " +
                                _vm._s(scope.row.motherPhone)
                            ),
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(scope.row.fullName))]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "Biệt danh",
                prop: "nickName",
                "min-width": "80",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "Nhóm", "min-width": "150" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "custom-scroll" },
                        _vm._l(scope.row.listNameGroup, function (item, index) {
                          return _c(
                            "ul",
                            {
                              key: index,
                              staticStyle: {
                                "text-align": "left",
                                "padding-left": "1rem",
                              },
                            },
                            [
                              _c("li", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(item) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "className",
                "min-width": "100",
                label: "Tên lớp",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                "min-width": "100",
                label: "Ngày nhập học",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(scope.row.dateStart))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                align: "center",
                "min-width": "100",
                label: "Ngày sinh",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(scope.row.birthDay))
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "gender",
                "min-width": "80",
                label: "Giới tính",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "distanceAge",
                "min-width": "80",
                label: "Độ tuổi",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                fixed: "right",
                label: "Tác vụ",
                width: "240",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddStudent(scope.row)
                            },
                          },
                        },
                        [_vm._v("Thêm\n          ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: scope.row.listNameGroup.length === 0,
                            size: "mini",
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.switchStudentToGroup(scope.row)
                            },
                          },
                        },
                        [_vm._v("Chuyển\n          ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: scope.row.listNameGroup.length === 0,
                            size: "mini",
                            type: "danger",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(scope.row)
                            },
                          },
                        },
                        [_vm._v("Xóa\n          ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "button-click row-data",
        staticStyle: { "margin-top": "15px" },
      },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              {
                staticStyle: {
                  display: "inline-flex",
                  "justify-content": "right",
                },
                attrs: { span: 14, offset: 1 },
              },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.dataSearch.page,
                    "page-sizes": _vm.listSizeLimit,
                    "page-size": this.dataSearch.limit,
                    layout: " prev, pager,sizes, next",
                    total: this.dataSearch.limit * _vm.totalPages,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.dataSearch, "page", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.dataSearch, "page", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                staticStyle: { float: "right" },
                on: { command: _vm.handleCommand },
              },
              [
                _c(
                  "el-button",
                  { staticClass: "button-over", attrs: { type: "success" } },
                  [
                    _vm._v("\n          Tác vụ\n          "),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]
                ),
                _c(
                  "el-dropdown-menu",
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "addToGroup" } },
                      [_vm._v("Thêm vào nhóm\n          ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "switchGroup" } },
                      [_vm._v("Chuyển nhóm\n          ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "deleteEachGroup" } },
                      [_vm._v("Xóa HS trong từng nhóm\n          ")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { command: "deleteMultipleGroup" } },
                      [_vm._v("Xóa nhiều nhóm\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("AddToGroupDialog", {
          ref: "addToGroupDialog",
          attrs: { dialogVisible: _vm.showAddToGroupDialog },
          on: { "dialog-close": _vm.closeAddToGroupDialog },
        }),
        _c("SwitchGroupDialog", {
          ref: "switchGroupDialog",
          attrs: { dialogVisible: _vm.showSwitchGroupDialog },
          on: { "dialog-close": _vm.closeSwitchGroupDialog },
        }),
        _c("DeleteEachGroupDialog", {
          ref: "deleteEachGroupDialog",
          attrs: { dialogVisible: _vm.showDeleteEachGroupDialog },
          on: { "dialog-close": _vm.closeDeleteEachGroupDialog },
        }),
        _c("DeleteMultipleGroupDialog", {
          ref: "deleteMultipleGroupDialog",
          attrs: { dialogVisible: _vm.showDeleteMultipleGroupDialog },
          on: { "dialog-close": _vm.closeDeleteMultipleGroupDialog },
        }),
        _c("AddStudentToGroupDialog", {
          ref: "addStudentToGroupDialog",
          attrs: {
            idKid: _vm.idKid.toString(),
            dialogVisible: _vm.showAddStudentToGroupDialog,
          },
          on: { "dialog-close": _vm.closeAddStudentToGroupDialog },
        }),
        _c("SwitchStudentToGroupDialog", {
          ref: "switchStudentToGroupDialog",
          attrs: {
            idKid: _vm.idKid.toString(),
            dialogVisible: _vm.showSwitchStudentToGroupDialog,
          },
          on: { "dialog-close": _vm.closeSwitchStudentToGroupDialog },
        }),
        _c("delete-one-child-multi-group", {
          ref: "dialogDeleteOneKid",
          attrs: { "dialog-visible": _vm.showDeleteOneChildMultiGroup },
          on: { "dialog-close": _vm.closeDialogDeleteOneKid },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }