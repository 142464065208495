<template>
  <div>
    <el-dialog
        title="Xóa nhiều nhóm"
        :visible.sync="dialogVisible"
        width="450px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        @open="handleOpen"
    >
      <el-form>
        <el-form-item v-if="listStuParams.statusOption===true" label="Chọn nhóm xóa">
          <el-select v-model="listGroupSelect" multiple size="small" style="width: 255px"
                     placeholder="Chọn nhóm cần xóa">
            <el-option
                v-for="(item,index) in listGroup"
                :key="index"
                :label="item.nameGroup"
                :value="item.id"
            >
            </el-option>

          </el-select>
        </el-form-item>
        <el-form-item>
          <el-col :span="24" style="display: inline-flex ;	justify-content: space-evenly;margin-left: 30px">
            <el-radio-group v-model="listStuParams.statusOption">
              <el-radio :label="true"> Chọn nhiều nhóm</el-radio>
              <el-radio :label="false">Chọn tất cả</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button type="success" @click="handleDeleteEachGroup" size="medium" style="margin-left: 170px"
                   icon="el-icon-circle-check" :disabled="listGroupSelect.length === 0 &&listStuParams.statusOption === true"> OK
        </el-button>
        <el-button type="danger" @click="closeDialog" size="medium" icon="el-icon-circle-close"> Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import studentGroupService from "@/services/Finance_Kids_V2/StudentGroupService";
import {mapState} from "vuex";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState('studentGroupModule', ["idKidList",'dataSearch'])
  },
  data() {
    return {
      listGroup: [],
      listGroupSelect: [],
      listStuParams: {
        idKidList: '',
        idGroup: '',
        statusOption: true  // false là chọn tất cả
      }
    }
  },
  methods: {
    handleOpen() {
      studentGroupService.getListKidsGroup().then((res) => {
        this.listGroup = res.data
      })
    },
    handleDeleteEachGroup() {
      this.listStuParams.idGroup = this.listGroupSelect.join(',')
      this.listStuParams.idKidList = this.idKidList
      this.$confirm('Xác nhận xóa học sinh khỏi nhóm ?', 'Cảnh báo', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        studentGroupService.removeAllGroupsForListStudents(this.listStuParams).then((res) => {
          this.$message({
            type: 'success',
            message:res.data.message
          });
          this.closeDialog()
        }).then(()=>{
          this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
        })
            .catch((er) => {
              console.log(er)
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Hủy thao tác'
        });
      });
    },
    closeDialog() {
      this.listGroupSelect = []
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
      this.$emit("dialog-close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 25px;
  margin-right: 100px;
}
</style>