<template>
  <el-dialog
    title="Thêm mới nhóm học sinh"
    :visible.sync="dialogVisible"
    width="40%"
    :before-close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form
      label-width="100px"
      :model="newKidsGroup"
      label-position="left"
      :rules="rules"
      ref="newKidsGroup"
    >
      <el-form-item label="Tên nhóm" prop="groupName">
        <el-input
          v-model="newKidsGroup.groupName"
          @keyup.enter.native="submitForm('newKidsGroup')"
          placeholder="Nhập tên nhóm"
        ></el-input>
        <span class="error-message">{{ customErrorMessages.newNameKt }}</span>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          :rows="1"
          type="textarea"
          autosize
          v-model="newKidsGroup.description"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          v-if="showSaveChange"
          mini
          @click="submitForm('newKidsGroup')"
      >
        <i class="el-icon-circle-check" />
        <span>Lưu</span>
      </el-button>
      <el-button type="danger" size="medium" @click="closeDialogByButton('newKidsGroup')">
        <i class="el-icon-circle-close" />
        <span>Đóng</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import KidsGroupService from "@/services/KidsService/KidsGroupService";
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: ()=> false,
    },
  },
  computed:{
    ...mapState('studentStore',['kidsGroupList'])
  },
  data() {
    return {
      newKidsGroup: {
        groupName: "",
        description: "",
      },
      customErrorMessages: {
        newNameKt: ""
      },
      showSaveChange: false,
      newKidsGroupCreated: null,
      loadingButton: false,
      rules: {
        groupName: [
          {
            validator: this.changeGroupName,
            trigger: "change"
          },
        ],
      },
    };
  },
  methods: {
    //Gọi về api để thay đổi dữ liệu
    ...mapActions('studentStore',['fetchDataKidsGroupList']),
    ...mapMutations('studentStore',['ADD_KidsGroupList']),
    //reset when click x
    closeDialog() {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      //reset form
      this.$refs["newKidsGroup"].resetFields();
      this.$emit("dialog-close");
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
    },
    changeGroupName(rule, value, callback){
      if (this.kidsGroupList.length !== 0){
        for (let i = 0; i < this.kidsGroupList.length ; i++) {
          if (!value) {// Kiểm tra nếu giá trị rỗng
            this.customErrorMessages.newNameKt = "Giá trị không được để trống!";
            this.showSaveChange = false
            break
          }else  if (value.toLowerCase().replace(/\s/g, "") === this.kidsGroupList[i].groupName.toLowerCase().replace(/\s/g, "")){
            this.customErrorMessages.newNameKt = "Tên nhóm: "+this.kidsGroupList[i].groupName + " đã tồn tại!";
            this.showSaveChange = false
            break
          }else {
            this.showSaveChange = true
            this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
            callback();
          }
        }
      }else {
        this.showSaveChange = true
        this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
        callback();
      }
    },
    async submitForm(fromData) {
     await this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
           KidsGroupService.create(this.newKidsGroup)
            .then((response) => {
              this.newKidsGroupCreated = response.data.data;
              this.$message({
                message: "Thêm mới nhóm thành công",
                type: "success",
              });
              this.ADD_KidsGroupList(this.newKidsGroupCreated);
              this.closeDialog();
              // this.reloadComponent();
            })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
              console.log(err);
            }).finally(()=>{
            this.loadingButton = false;
          })
        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },
    reloadComponent() {
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner,
/deep/.el-textarea__inner {
  border: none;
  border-radius: 0;
  resize: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #d9d9d9;
}
/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

.error-message {
  color: red;
}
</style>
