<template>
  <div>
    <el-dialog
        title="Xóa nhóm"
        :visible.sync="dialogVisible"
        width="450px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="Chọn nhóm xóa">
          <el-select v-model="params.idGroup"   size="small" style="width: 255px"
                     placeholder="Chọn nhóm cần xóa">
            <el-option
                v-for="(item,index) in listGroup"
                :key="index"
                :label="item.nameGroup"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button type="success" @click="handleDeleteEachGroup" size="medium" style="margin-left: 170px"
                   icon="el-icon-circle-check" :disabled="params.idGroup === null"> OK
        </el-button>
        <el-button type="danger" @click="closeDialog" size="medium" icon="el-icon-circle-close"> Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import studentGroupService from "@/services/Finance_Kids_V2/StudentGroupService";
import {mapState} from "vuex";

export default {
  name:'DeleteOneChildMultiGroup',
  props: {

    dialogVisible: {
      Type: Boolean,
      default: () => false
    },
  },
  computed:{
    ...mapState('studentGroupModule',['dataSearch'])
  },
  data() {
    return {
      params:{
        idKid:Number,
        idGroup: null,
      },
      listGroup:[]
    }
  },
  methods: {
    getDataDelete(idKid){
      this.params.idKid=idKid
      studentGroupService.getListKidsGroupByStudentId(idKid).then((res)=>{
        this.listGroup=res.data
      })
    },
    handleDeleteEachGroup() {
      this.$confirm('Xác nhận xóa học sinh khỏi nhóm ?', 'Cảnh báo', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        studentGroupService.removeOneGroupForOneKid(this.params).then((res)=>{
          this.$message({
            type:'success',
            message:res.data.message
          })
        }).then(()=>{
          this.closeDialog()
        })
            .catch((er) => {
              console.log(er)
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Hủy thao tác'
        });
      });
    },
    closeDialog() {
      this.params.idGroup = null
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
      this.$emit("dialog-close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 100px;
}
</style>