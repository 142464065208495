var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật nhóm học sinh",
        visible: _vm.dialogVisible,
        width: "40%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "editKidsGroup",
          attrs: {
            "label-width": "120px",
            model: _vm.editKidsGroup,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên nhóm", prop: "groupName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên nhóm" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submitForm("editKidsGroup")
                  },
                },
                model: {
                  value: _vm.editKidsGroup.groupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.editKidsGroup, "groupName", $$v)
                  },
                  expression: "editKidsGroup.groupName",
                },
              }),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.customErrorMessages.newNameKt)),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 1,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập mô tả",
                },
                model: {
                  value: _vm.editKidsGroup.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.editKidsGroup, "description", $$v)
                  },
                  expression: "editKidsGroup.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.showSaveChange
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("editKidsGroup")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("editKidsGroup")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }