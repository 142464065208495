var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm học sinh vào nhóm",
        visible: _vm.dialogVisible,
        width: "80%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpen,
      },
    },
    [
      _c("div", { staticStyle: { "margin-bottom": "20px" } }),
      _c(
        "el-transfer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingDataTransfer,
              expression: "loadingDataTransfer",
            },
          ],
          attrs: {
            "element-loading-text": _vm.$tableLoading,
            filterable: "",
            "filter-placeholder": "Nhập thông tin học sinh",
            titles: ["Tất cả học sinh", "Học sinh trong nhóm"],
            "button-texts": ["", ""],
            data: _vm.dataSource,
          },
          model: {
            value: _vm.dataTarget,
            callback: function ($$v) {
              _vm.dataTarget = $$v
            },
            expression: "dataTarget",
          },
        },
        [void 0],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }