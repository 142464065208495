var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Xóa nhóm",
            visible: _vm.dialogVisible,
            width: "450px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "Chọn nhóm xóa" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "255px" },
                      attrs: {
                        size: "small",
                        placeholder: "Chọn nhóm cần xóa",
                      },
                      model: {
                        value: _vm.params.idGroup,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "idGroup", $$v)
                        },
                        expression: "params.idGroup",
                      },
                    },
                    _vm._l(_vm.listGroup, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.nameGroup, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "170px" },
                  attrs: {
                    type: "success",
                    size: "medium",
                    icon: "el-icon-circle-check",
                    disabled: _vm.params.idGroup === null,
                  },
                  on: { click: _vm.handleDeleteEachGroup },
                },
                [_vm._v(" OK\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "medium",
                    icon: "el-icon-circle-close",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v(" Đóng")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }