<template>
  <div>
    <el-dialog
        title="Xóa từng nhóm"
        :visible.sync="dialogVisible"
        width="350px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        @open = "handleOpen"
    >
      <el-form>
        <el-form-item label="Nhóm xóa: ">
          <span>{{nameGroup}}</span>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button type="success" @click="handleDeleteEachGroup" size="medium" style="margin-left: 110px"
                   icon="el-icon-circle-check"> OK
        </el-button>
        <el-button type="danger" @click="closeDialog" size="medium" icon="el-icon-circle-close"> Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import studentGroupService from "@/services/Finance_Kids_V2/StudentGroupService";
import {FILTER_STUDENTS_GROUP} from "@/constant/actions";

export default {
  props: {
    dialogVisible: {
      Type: Boolean,
      default: () => false
    },
    idKid: null,
    multipleSelection: [],
  },
  data() {
    return {
      nameGroup:"",
      paramRemove: {
        idKidList: '',
        idGroup: ''
      }
    }
  },
  created() {
    this.$store.dispatch('studentStore/fetchDataKidsGroupList');
  },
  computed: {
    ...mapState('studentStore', ['kidsGroupList']),
    ...mapState('studentGroupModule', ['idKidList','dataSearch']),
  },
  methods: {
    ...mapActions([FILTER_STUDENTS_GROUP]),
    handleOpen(){
      for (let i = 0; i < this.kidsGroupList.length; i++) {
        if (this.kidsGroupList[i].id === this.dataSearch.idGroup){
          this.nameGroup = this.kidsGroupList[i].groupName
          break
        }
      }
    },
    handleDeleteEachGroup() {
      this.paramRemove.idGroup = this.dataSearch.idGroup
      this.paramRemove.idKidList = this.idKidList
      this.$confirm('Xác nhận xóa học sinh khỏi nhóm ?', 'Cảnh báo', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        studentGroupService.removeOneGroupForListStudents(this.paramRemove)
            .then((res) => {
              this.$message.success({
                message:res.data.message
              });
              this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
            }).catch((err) => {
          console.log(err)
          this.$message.error({
            message: "Thao tác thất bại!",
          });
        }).finally(() => {
          this.closeDialog();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Hủy thao tác'
        });
      });
    },
    closeDialog() {
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
      this.$emit("dialog-close", false);
    },
    reloadComponent() {
      location.reload();
    },
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 100px;
}
</style>