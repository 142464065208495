<template>
  <div class="content">
    <section style="height: 2.5rem">
      <div class="filter-student" v-if="showTabFilter === true">
        <!-- chọn trạng thái -->
        <el-select
            style="width: 120px"
            class="button-left-status"
            v-model="dataSearch.kidStatus"
            placeholder="Trạng thái"
            @change="handleChange"
        >
          <el-option
              v-for="(item,index) in kidStatusList"
              :key="index"
              :value="item.key"
              :label="item.value"
          ></el-option>
        </el-select>

        <!--      Chọn nhóm-->
        <el-select
            style="width: 150px"
            class="button-left-class"
            filterable
            v-model="dataSearch.idGroup"
            placeholder="Chọn nhóm"
            @change="handleChange"
            clearable
        >
          <el-option
              v-for="item in kidsGroupList"
              :key="item.id"
              :value="item.id"
              :label="item.groupName"
          ></el-option>
        </el-select>
        <!-- chọn khối -->
        <el-select
            style="width: 150px"
            class="button-left-class"
            v-model="dataSearch.idGrade"
            placeholder="Chọn khối"
            clearable
            @change="getListClass"
        >
          <el-option
              v-for="(item,index) in listGrade"
              :key="index"
              :value="item.id"
              :label="item.gradeName"
          ></el-option>
        </el-select>

        <!-- chọn lớp -->
        <el-select
            style="width: 150px"
            class="button-left-class"
            filterable
            v-model="dataSearch.idClass"
            placeholder="Chọn lớp"
            clearable
            @change="handleChange"
        >
          <el-option
              v-for="item in classList"
              :key="item.id"
              :value="item.id"
              :label="item.className"
          ></el-option>
        </el-select>
        <!-- nhập ô tìm kiếm -->
        <el-input
            class="button-left-class"
            style="width: 280px"
            placeholder="Nhập tên, biệt danh, tài khoản"
            clearable
            v-model="dataSearch.name"
            @clear="handleChange"
            @keyup.enter.native="handleChange"
        >
          <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleChange"
          ></el-button>
        </el-input>
        <el-button @click="addFilters">
          <i
              class="el-icon-circle-plus"
              :style="{color: showAddFilterColor()}"
          ></i>
        </el-button>
        <el-button v-if="checkFilter()" @click="resetFilterDefault()">
          <i
              class="el-icon-refresh"
          >
          </i>
        </el-button>
      </div>
    </section>
    <el-tabs type="card" v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane label="Danh sách Nhóm" name="listGroup">
        <list-student-group/>
      </el-tab-pane>
      <!--      tab2 danh sach hoc sinh-->
      <el-tab-pane label="Chi tiết" name="listStudent">
        <student-group-detail :loading-data="loadingTable" @set-loading="handleSetLoading"/>
      </el-tab-pane>
    </el-tabs>
    <AddFiltersDialog
        :dialogVisible="showAddFilters"
        @dialog-close="closeAddFiltersDialog"
        ref="addFiltersDialog"
    />
  </div>

</template>

<script>
import StudentGroupDetail from "@/views/student/ViewStudentGroup/StudentGroupDetail.vue";
import ListStudentGroup from "@/views/student/ViewStudentGroup/ListStudentGroup.vue";
import AddFiltersDialog from "@/views/student/StudentGroupDialog/AddFiltersDialog.vue";
import {kidStatusList} from "@/constant/listCommon"
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import {mapActions, mapState} from "vuex";
import color from "color";

export default {
  name: 'StudentGroup1',
  components: {
    ListStudentGroup,
    AddFiltersDialog,
    StudentGroupDetail,

  },
  data() {
    return {
      loadingTable: false,
      listGrade: [],
      classList: [],
      //pagination field
      listSizeLimit: [30, 40, 50],
      dataSearch: {
        idGroup: '',
        idGrade: '',
        idClass: '',
        kidStatus: kidStatusList[0].key,
        startBirthday: '',
        endBirthday: '',
        startComeIn: '',
        endComeIn: '',
        name: '',
        startAge: null,
        endAge: null,
        page: 1,
        limit: 30,
      },
      nameGroup: "",
      kidStatusList,
      activeName: "listGroup",
      showAddFilters: false,
      showTabFilter: false,
    };
  },
  created() {
    this.initDataSearch();
  },
  computed: {
    ...mapState('studentGroupModule', ['totalPages','resetFilter']),
    ...mapState('studentStore', ['kidsGroupList']),
    getAppTypeUserLogin() {
      return this.$store.state.auth.user.appType
    },

  },

  methods: {
    color() {
      return color
    },
    ...mapActions('studentStore', ['fetchDataKidsGroupList']),
    ...mapActions('studentGroupModule', ['filter_students_group']),
    /**
     *    khởi tạo dữ liệu
     */
    initDataSearch() {
      this.getListGroup();
      this.getListGrade();
    },
    getIdGroup() {
      if (this.kidsGroupList.length > 0) {
        this.dataSearch.idGroup = this.kidsGroupList[0].id;
      }
    },
    getListGroup() {
      this.fetchDataKidsGroupList().then(() => {
        this.getIdGroup();
      }).then(() => {
        // this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
        this.filter_students_group(this.dataSearch)
      })
    },
    getListGrade() {
      financeKidsV2Service.getGradeInPrinciple().then((res) => {
        res.data && res.data.data ? this.listGrade = res.data.data : this.listGrade = [];
        // this.dataSearch.idGrade = this.listGrade[0].id;
      })
    },
    // Lấy danh sách lớp thuộc khối
    getListClass() {
      this.dataSearch.idClass = "";
      financeKidsV2Service
          .getClassInGrade(this.dataSearch.idGrade)
          .then((res) => {
            res.data && res.data.data ? this.classList = res.data.data : this.classList = [];
          })
          .then(() => {
            this.handleChange();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    /**
     * thay đổi filter
     *
     */
    handleChange() {
      this.loadingTable = true
      // Vào store lấy danh sách học sinh theo filter
      // this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
      this.filter_students_group(this.dataSearch)
    },
    handleClickTab(tab) {
      if (tab.name === "listGroup") {
        this.$store.dispatch('studentStore/fetchDataKidsGroupList');
        this.showTabFilter = false;
      } else if (tab.name === "listStudent") {
        this.showTabFilter = true;
      }
    },
    closeAddFiltersDialog() {
      this.showAddFilters = false
    },
    addFilters() {
      this.showAddFilters = true
    },

    handleSetLoading() {
      this.loadingTable = false
    },

    showAddFilterColor() {
      if (this.dataSearch.startBirthday !== '' ||
          this.dataSearch.startComeIn !== '' ||
          this.dataSearch.endBirthday !== '' ||
          this.dataSearch.endComeIn !== '' ||
          this.dataSearch.startAge !== null ||
          this.dataSearch.endAge !== null) {
        return 'red'
      }
    },

    checkFilter() {
      return this.dataSearch.idGrade !== "" ||
          this.dataSearch.idClass !== "" ||
          this.dataSearch.startBirthday !== "" ||
          this.dataSearch.endBirthday !== "" ||
          this.dataSearch.startComeIn !== "" ||
          this.dataSearch.endComeIn !== "" ||
          this.dataSearch.name !== "" ||
          this.dataSearch.startAge !== null ||
          this.dataSearch.endAge !== null;
    },

    resetFilterDefault() {
      this.$store.commit('studentGroupModule/set_reset_filter', true)
      this.dataSearch.idGrade = ""
      this.dataSearch.idClass = ""
      this.dataSearch.startBirthday = ""
      this.dataSearch.endBirthday = ""
      this.dataSearch.startComeIn = ""
      this.dataSearch.endComeIn = ""
      this.dataSearch.name = ""
      this.dataSearch.startAge = null
      this.dataSearch.endAge = null
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}

/deep/ .el-tabs__nav-scroll {
  float: right;
}

/deep/ [data-v-5ed4c166] .el-tabs--card > .el-tabs__header {
  border-bottom: none;
  margin-top: -8px;
}

/deep/ .el-tabs__nav-wrap {
  margin-top: 0px;
  margin-bottom: -15px;
}

/deep/ .el-tabs__nav {
  background: #a0a19ce0;
}

/deep/ .el-tabs__item {
  color: white;
}

/deep/ .el-icon-loading:before {
  content: "\e6cf";
}

/deep/ .el-loading-spinner .el-loading-text {
  color: #606266;
  font-size: 15px;
}

/deep/ .el-loading-spinner {
  top: 67%;
  width: 100%;
  text-align: center;
  position: absolute;
}

/deep/ .el-tabs__item.is-active {
  color: white;
  background: #78a5e7;
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}

.table-content {
  .el-table {
    border-top-left-radius: 5px;
  }
}

.content .row-data {
  margin-bottom: 20px;
}

.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0;
  margin: 3px 0;
  background-color: rgb(95, 180, 74);
  border: 1px solid #ebeef5;
  border-radius: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-dropdown-menu li {
  color: white;
  border-top: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-table th > .cell {
  text-align: center !important;
}

/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}

/deep/ .el-pagination .el-select .el-input {
  width: 75px;
}

/deep/ .el-table th.gutter {
  background-color: #78a5e7;
}

/deep/ .el-icon-loading:before {
  content: "\e6cf";
}

/deep/ .el-loading-spinner .el-loading-text {
  color: #606266;
  font-size: 15px;
}

/deep/ .el-loading-spinner {
  top: 67%;
  width: 100%;
  text-align: center;
  position: absolute;
}

/deep/ .el-checkbox__inner {
  border: 1px solid gray;
}

.content {
  .row-data {
    margin-bottom: 20px;
  }

  .button-over {
    border-radius: 0;
    margin-left: 3px;
  }

  .button-click {
    text-align: right;
  }

  .table-content {
    .el-table {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: 1px solid #78a5e7;
    }
  }

  .button-click-left {
    display: inline-block;
  }

  .paging-click {
    .el-pagination {
      text-align: right;
      margin: 30px 0;
    }
  }
}

/deep/ .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}

.filter-student {
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-wrap: wrap;
}

</style>
