var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Xóa từng nhóm",
            visible: _vm.dialogVisible,
            width: "350px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.handleOpen,
          },
        },
        [
          _c(
            "el-form",
            [
              _c("el-form-item", { attrs: { label: "Nhóm xóa: " } }, [
                _c("span", [_vm._v(_vm._s(_vm.nameGroup))]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "110px" },
                  attrs: {
                    type: "success",
                    size: "medium",
                    icon: "el-icon-circle-check",
                  },
                  on: { click: _vm.handleDeleteEachGroup },
                },
                [_vm._v(" OK\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "medium",
                    icon: "el-icon-circle-close",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v(" Đóng")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }