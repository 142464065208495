<template>
  <div>
    <el-dialog
        title="Chuyển nhóm"
        :visible.sync="dialogVisible"
        width="450px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
        @open = "handleOpen"
    >
      <el-form>
        <el-form-item label="Chọn nhóm cũ">
          <el-select v-model="selectOldGroup" size="small" style="width: 255px; margin-left: 8px" placeholder="Chọn nhóm cũ">
            <el-option
                v-for="(item,index) in listOldGroup"
                :key="index"
                :label="item.nameGroup"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Chọn nhóm mới">
          <el-select v-model="selectNewGroup" size="small" style="width: 255px" placeholder="Chọn nhóm mới">
            <el-option
                v-for="(item,index) in listNewGroup"
                :key="index"
                :label="item.nameGroup"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button type="success" @click="handleSwitchStudentToGroup"  size="medium" style="margin-left: 175px" icon="el-icon-circle-check" :loading="loadingSwitchStudent" :disabled="selectOldGroup === ''||selectNewGroup === ''"> OK</el-button>
        <el-button type="danger" @click="closeDialog" size="medium" icon="el-icon-circle-close"> Đóng</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import StudentGroupService from "@/services/Finance_Kids_V2/StudentGroupService";
import {mapState} from "vuex";

export default {
  computed:{
    ...mapState('studentGroupModule',['dataSearch'])
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: ()=> false,
    },
    idKid:{
      type: String,
      default: () => ""
    }
  },
  data(){
    return{
      loadingSwitchStudent: false,
      listOldGroup: [],
      listNewGroup: [],
      selectOldGroup: "",
      selectNewGroup: "",
      studentParams:{
        idKid:"",
        idGroupOld:"",
        idGroupNew: "",
      }
    }
  },
  methods:{
    async handleOpen(){
      await StudentGroupService.getListKidsGroupByStudentId(this.idKid).then((response) =>{
        this.listOldGroup = response.data
        console.log("data ",response.data)
      })
      await StudentGroupService.getListKidsGroup().then((response) =>{
        this.listNewGroup = response.data
      })

      let  listIdOld = [];
      for (let i = 0; i < this.listOldGroup.length; i++) {
        listIdOld.push(this.listOldGroup[i].id)
      }

      let listIndex = [];

      for (let i = 0; i < this.listNewGroup.length; i++) {
        for (let j = 0; j < listIdOld.length; j++) {
          if (listIdOld[j] === this.listNewGroup[i].id){
            listIndex.push(i)
            break;
          }
        }
      }

      this.listNewGroup = this.listNewGroup.filter((item, index) => !listIndex.includes(index));
    },

    handleSwitchStudentToGroup(){
      this.loadingSwitchStudent = true
      this.studentParams.idKid  = this.idKid
      this.studentParams.idGroupOld = this.selectOldGroup
      this.studentParams.idGroupNew = this.selectNewGroup
      StudentGroupService.changeGroupForAStudent(this.studentParams).then(() =>{
        this.$message({
          type: "success",
          message: "Chuyển nhóm thành công."
        })
        this.loadingSwitchStudent = false
        this.closeDialog()
      })
    },
    closeDialog() {
      this.loadingSwitchStudent = false
      this.selectOldGroup= ""
      this.selectNewGroup= ""
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
      this.$emit("dialog-close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
  margin-right: 100px;
}
</style>