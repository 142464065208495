var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.kidsGroupList,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "groupName", label: "Tên nhóm" },
              }),
              _c("el-table-column", {
                attrs: { prop: "description", label: "Mô tả nhóm" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "kidsNumber",
                  label: "Số lượng",
                  align: "center",
                },
              }),
              _vm.checkPermission(["kids_group_update"])
                ? _c("el-table-column", {
                    attrs: { label: "Tác vụ", width: "220", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTransfer(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Thêm\n          ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Sửa\n          ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        scope.$index,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Xóa\n          ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2004097794
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                display: "inline-flex",
                "justify-content": "right",
              },
              attrs: { span: 20, offset: 4 },
            },
            [
              _c("el-pagination", {
                staticStyle: { "margin-right": "12rem", "margin-left": "auto" },
                attrs: {
                  background: "",
                  "current-page": _vm.dataSearch.page,
                  "page-size": _vm.maxPageItem,
                  total: _vm.pageTotal,
                  layout: " prev, pager,sizes, next",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.dataSearch, "page", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.dataSearch, "page", $event)
                  },
                },
              }),
              _vm.checkPermission(["kids_group_update"])
                ? _c(
                    "el-button",
                    {
                      staticClass: "button-over",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.createGradeDialog()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v("\n        Thêm nhóm mới\n      "),
                    ]
                  )
                : _vm._e(),
              _vm.checkPermission(["kids_list_app"])
                ? _c(
                    "el-dropdown",
                    { on: { command: _vm.handleSendNotify } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-over",
                          attrs: { type: "success" },
                        },
                        [
                          _vm._v("\n          Gửi tin\n          "),
                          _c("i", { staticClass: "el-icon-caret-bottom" }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        [
                          _vm.checkPermission(["kids_list_app"])
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "createNotifyApp" } },
                                [_vm._v("Gửi qua App\n          ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "button-over",
                  attrs: { type: "success", loading: _vm.loaddingExcel },
                  on: {
                    click: function ($event) {
                      return _vm.exportExcel()
                    },
                  },
                },
                [_vm._v("Xuất File Excel\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CreateStudentGroup", {
        ref: "CreateStudentGroup",
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateMethod()
          },
        },
      }),
      _c("UpdateStudentGroup", {
        attrs: {
          dialogVisible: _vm.showUpdateDialog,
          editKidsGroup: _vm.updateKidsGroup,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateMethod()
          },
        },
      }),
      _c("StudentGroupTransfer", {
        ref: "StudentGroupTransfer",
        attrs: { dialogVisible: _vm.showTransferDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseTranferMethod()
          },
        },
      }),
      _c("CreateStudentNotify", {
        ref: "CreateStudentNotify",
        attrs: {
          dataGroupNotifyList: _vm.dataGroupNotifyList,
          dialogVisibleEx: _vm.showCreateNotifyDialog,
          sendNotificationTeacherOnApp: _vm.sendNotificationTeacherOnApp,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateNotifyMethod()
          },
        },
      }),
      _c("CreateStudentNotifySms", {
        ref: "CreateStudentNotifySms",
        attrs: {
          dataGroupNotifySmsList: _vm.dataGroupNotifySmsList,
          dialogVisibleSms: _vm.showCreateNotifySmsDialog,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateNotifySmsMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }