<template>
  <el-dialog
      title="Thêm bộ lọc cho học sinh"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="closeDialog"
      @open="handleOpen"
      :close-on-click-modal="false"
  >
    <el-form
        label-width="120px"
        :model="dataInput"
        label-position="left"
        ref="dataInput"
    >
      <el-form-item label="Chọn loại" prop="type">
        <el-select v-model="dataInput.type" style="width: 100%" @change="handleTypeChange" clearable>
          <el-option label="Ngày nhập học" value="startDate"></el-option>
          <el-option label="Ngày sinh" value="birthday"></el-option>
          <el-option label="Độ tuổi" value="age"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Khoảng ngày" v-if="this.showStartDate === true">
        <el-date-picker
            style="width: 100%"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            v-model="dataInput.dateList"
            type="daterange"
            unlink-panels
            range-separator="-"
            start-placeholder="Ngày bắt đầu"
            end-placeholder="Ngày kết thúc"
            :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Khoảng ngày" v-if="this.showBirthday === true">
        <el-date-picker
            style="width: 100%"
            value-format="yyyy-MM-dd"
            format="dd-MM-yyyy"
            v-model="dataInput.dateBirthday"
            type="daterange"
            unlink-panels
            range-separator="-"
            start-placeholder="Ngày bắt đầu"
            end-placeholder="Ngày kết thúc"
            :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Bắt đầu" v-if="this.showAge === true">
        <el-input type="Number" size="smail" style="width: 70px" v-model="selectYearStart"/>
        <span style="margin-left: 10px;margin-right: 10px">tuổi</span>
        <el-select v-model="selectMonthStart" placeholder="Chọn tháng" style="width: 70px">
          <el-option
              v-for="item in listMonth"
              :key="item"
              :label="item.toString()"
              :value="item">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">tháng</span>
      </el-form-item>
      <el-form-item label="Kết thúc" v-if="this.showAge === true">
        <el-input type="Number" size="smail" style="width: 70px" v-model="selectYearEnd"/>
        <span style="margin-left: 10px;margin-right: 10px">tuổi</span>
        <el-select v-model="selectMonthEnd" placeholder="Chọn tháng" style="width: 70px">
          <el-option
              v-for="item in listMonth"
              :key="item"
              :label="item.toString()"
              :value="item">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">tháng</span>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm()"
      >
        <i class="el-icon-circle-check"/>
        <span>{{ $t("button.OK") }}</span>
      </el-button>
      <el-button type="danger" size="medium" @click="closeDialog()">
        <i class="el-icon-circle-close"/>
        <span>{{ $t("button.close") }}</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  name: 'AddFilterDialog',
  computed: {
    ...mapState("studentGroupModule", ['dataSearch', 'type','resetFilter'])
  },
  props: {
    dialogVisible: {
      Type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      endAge: null,
      startAge: null,
      pickerOptions: this.$funcCommon.quickOptionDate(),
      selectMonthStart: 0,
      selectYearStart: 0,
      selectMonthEnd: 0,
      selectYearEnd: 0,
      listMonth: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      dataInput: {
        type: this.type,
        dateList: [],
        dateBirthday: [],
      },
      loadingButton: false,
      showStartDate: false,
      showBirthday: false,
      showAge: false,
    };
  },
  methods: {
    handleOpen() {
      if (this.resetFilter === true) {
        this.dataInput.type = ""
        this.$store.commit('studentGroupModule/set_type_input', this.dataInput.type)
        this.handleClear()
      }
      if (this.type) {
        this.dataInput.type = this.type
      }
    },
    handleTypeChange() {
      if (this.dataInput.type === "startDate") {
        this.showStartDate = true;
        this.showBirthday = false;
        this.showAge = false;
      } else if (this.dataInput.type === "birthday") {
        this.showStartDate = false;
        this.showBirthday = true;
        this.showAge = false;
      } else if (this.dataInput.type === "age") {
        this.showStartDate = false;
        this.showBirthday = false;
        this.showAge = true;
      } else if (this.dataInput.type === "") {
        this.handleClear()
      }
    },
    handleClear() {
      this.dataSearch.startComeIn = ""
      this.dataSearch.endComeIn = ""
      this.dataSearch.startBirthday = ""
      this.dataSearch.endBirthday = ""
      this.dataInput.dateList = []
      this.dataInput.dateBirthday = []
      this.startAge = null;
      this.endAge = null;
      this.selectMonthStart = 0
      this.selectYearStart = 0
      this.selectMonthEnd = 0
      this.selectYearEnd = 0
      this.showAge = false
      this.showBirthday = false
      this.showStartDate = false
    },
    //reset when click x
    closeDialog() {
      this.$emit("dialog-close", false);
      if (this.type === "startDate") {
        this.showStartDate = true;
        this.showBirthday = false;
        this.showAge = false;
      } else if (this.type === "birthday") {
        this.showStartDate = false;
        this.showBirthday = true;
        this.showAge = false;
      } else if (this.type === "age") {
        this.showStartDate = false;
        this.showBirthday = false;
        this.showAge = true;
      } else if (this.type === "") {
        this.handleClear()
      }
    },
    submitForm() {
      this.startAge = this.selectYearStart * 12 + this.selectMonthStart
      this.endAge = this.selectYearEnd * 12 + this.selectMonthEnd
      // Kiểm tra ngày nhập học
      if (this.dataInput.dateList === null){
        this.dataSearch.startComeIn = ""
        this.dataSearch.endComeIn = ""
      } else if (this.dataInput.dateList.length === 0) {
        this.dataInput.dateList = [];
      } else {
        this.dataSearch.startComeIn = moment(this.dataInput.dateList[0]).format('YYYY-MM-DD')
        this.dataSearch.endComeIn = moment(this.dataInput.dateList[1]).format('YYYY-MM-DD')
      }
      //  Kiểm tra ngày sinh
      if(this.dataInput.dateBirthday === null){
        this.dataSearch.startBirthday = ""
        this.dataSearch.endBirthday = ""
      }
      else if (this.dataInput.dateBirthday.length === 0) {
        this.dataInput.dateBirthday = [];
      } else {
        this.dataSearch.startBirthday = moment(this.dataInput.dateBirthday[0]).format('YYYY-MM-DD')
        this.dataSearch.endBirthday = moment(this.dataInput.dateBirthday[1]).format('YYYY-MM-DD')
      }

      // Kiểm tra tuổi
      if (this.startAge === 0 && this.endAge === 0) {
        this.dataSearch.startAge = null
        this.dataSearch.endAge = null
      } else if (this.startAge === 0 && this.endAge !== 0) {
        this.dataSearch.startAge = null
        this.dataSearch.endAge = this.endAge
      } else if (this.startAge !== 0 && this.endAge === 0) {
        this.dataSearch.startAge = this.startAge
        this.dataSearch.endAge = null
      } else {
        this.dataSearch.startAge = this.startAge
        this.dataSearch.endAge = this.endAge
      }
      this.$store.commit('studentGroupModule/set_reset_filter', false)
      this.$store.commit('studentGroupModule/set_type_input', this.dataInput.type)
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
      this.closeDialog()
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
