<template>
  <div :key="componentKey">
    <div class="table-content row-data">
      <el-table
          :empty-text="$tableEmpty"
          v-loading="loadingData"
          :element-loading-text="$tableLoading"
          :max-height="$tableMaxHeight"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255,255,255, 0)"
          :data="studentList"
          highlight-current-row
          :header-cell-style="tableHeaderColor"
          @selection-change="handleSelectionChange"
          border
      >
        <el-table-column
            type="selection"
            align="center"
            width="50"
        ></el-table-column>
        <el-table-column
            type="index"
            align="center"
            label="STT"
            width="50"
        ></el-table-column>
        <el-table-column
            min-width="130"
            label="Họ tên"
            prop="fullName"
        >
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div slot="content">Mã học sinh: {{scope.row.kidCode}}<br>Bố/Mẹ: {{scope.row.fatherPhone}} - {{scope.row.motherPhone}}</div>
              <span>{{ scope.row.fullName }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            label="Biệt danh"
            prop="nickName"
            min-width="80"
            align="center"
        ></el-table-column>
        <el-table-column
            label="Nhóm"
            min-width="150"
        >
          <template slot-scope="scope">
            <div class="custom-scroll">
                <ul :key="index" v-for="(item,index) in scope.row.listNameGroup "
                    style="text-align: left;padding-left: 1rem">
                  <li>
                    {{ item }}
                  </li>
                </ul>
            </div>

          </template>

        </el-table-column>
        <el-table-column
            prop="className"
            min-width="100"
            label="Tên lớp"
            align="center"
        ></el-table-column>
        <el-table-column align="center" min-width="100" label="Ngày nhập học">
          <template slot-scope="scope">
            <span>{{ scope.row.dateStart | formatDate }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="100" label="Ngày sinh">
          <template slot-scope="scope">
            <span>{{ scope.row.birthDay | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="gender"
            min-width="80"
            label="Giới tính"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="distanceAge"
            min-width="80"
            label="Độ tuổi"
            align="center"
        ></el-table-column>

        <el-table-column
            fixed="right"
            label="Tác vụ"
            width='240'
            align="center"
        >
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="success"
                @click="handleAddStudent(scope.row)"
            >Thêm
            </el-button
            >
            <el-button
                :disabled="scope.row.listNameGroup.length === 0"
                size="mini"
                type="primary"
                @click="switchStudentToGroup(scope.row)"
            >Chuyển
            </el-button
            >
            <el-button
                :disabled="scope.row.listNameGroup.length === 0"
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)"
            >Xóa
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="button-click row-data" style="margin-top: 15px">
      <el-row >
        <el-col :span="14" :offset="1" style=" display: inline-flex;justify-content: right ">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="dataSearch.page"
              :page-sizes="listSizeLimit"
              :page-size="this.dataSearch.limit"
              layout=" prev, pager,sizes, next"
              :total="this.dataSearch.limit * totalPages"
          >
          </el-pagination>
        </el-col>
        <el-dropdown  style="float: right" @command="handleCommand">
          <el-button class="button-over" type="success">
            Tác vụ
            <i class="el-icon-caret-bottom"/>
          </el-button>
          <el-dropdown-menu>
            <el-dropdown-item
                command="addToGroup"
            >Thêm vào nhóm
            </el-dropdown-item
            >
            <el-dropdown-item
                command="switchGroup"
            >Chuyển nhóm
            </el-dropdown-item
            >
            <el-dropdown-item
                command="deleteEachGroup"
            >Xóa HS trong từng nhóm
            </el-dropdown-item
            >
            <el-dropdown-item
                command="deleteMultipleGroup"
            >Xóa nhiều nhóm
            </el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    </div>

    <div>
      <AddToGroupDialog
          :dialogVisible="showAddToGroupDialog"
          @dialog-close="closeAddToGroupDialog"
          ref="addToGroupDialog"
      />
      <SwitchGroupDialog
          :dialogVisible="showSwitchGroupDialog"
          @dialog-close="closeSwitchGroupDialog"
          ref="switchGroupDialog"
      />
      <DeleteEachGroupDialog
          :dialogVisible="showDeleteEachGroupDialog"
          @dialog-close="closeDeleteEachGroupDialog"
          ref="deleteEachGroupDialog"
      />
      <DeleteMultipleGroupDialog
          :dialogVisible="showDeleteMultipleGroupDialog"
          @dialog-close="closeDeleteMultipleGroupDialog"
          ref="deleteMultipleGroupDialog"
      />
      <AddStudentToGroupDialog
          :idKid="idKid.toString()"
          :dialogVisible="showAddStudentToGroupDialog"
          @dialog-close="closeAddStudentToGroupDialog"
          ref="addStudentToGroupDialog"
      />
      <SwitchStudentToGroupDialog
          :idKid="idKid.toString()"
          :dialogVisible="showSwitchStudentToGroupDialog"
          @dialog-close="closeSwitchStudentToGroupDialog"
          ref="switchStudentToGroupDialog"
      />
      <delete-one-child-multi-group :dialog-visible="showDeleteOneChildMultiGroup"
                                    @dialog-close="closeDialogDeleteOneKid" ref="dialogDeleteOneKid"/>
    </div>
  </div>
</template>
<script>
import AddToGroupDialog from "@/views/student/StudentGroupDialog/AddToGroupDialog.vue";
import SwitchGroupDialog from "@/views/student/StudentGroupDialog/SwitchGroupDialog.vue";
import DeleteEachGroupDialog from "@/views/student/StudentGroupDialog/DeleteEachGroupDialog.vue";
import DeleteMultipleGroupDialog from "@/views/student/StudentGroupDialog/DeleteMultipleGroupDialog.vue";
import {mapActions, mapState} from "vuex";
import AddStudentToGroupDialog from "@/views/student/StudentGroupDialog/AddStudentToGroupDialog.vue";
import DeleteOneChildMultiGroup from "@/views/student/StudentGroupDialog/DeleteOneChildMultiGroupDialog.vue";
import SwitchStudentToGroupDialog from "@/views/student/StudentGroupDialog/SwitchStudentToGroupDialog.vue";

export default {
  name: 'StudentGroupDetail',
  components: {
    DeleteOneChildMultiGroup,
    SwitchStudentToGroupDialog,
    AddStudentToGroupDialog,
    DeleteMultipleGroupDialog,
    DeleteEachGroupDialog,
    SwitchGroupDialog,
    AddToGroupDialog
  },
  data() {
    return {
      loadingTable: false,
      listSizeLimit: [30, 40, 50, 100, 300, 500, 1000],
      idKid: "",
      multipleSelection: [],
      showAddToGroupDialog: false,
      showSwitchGroupDialog: false,
      showDeleteEachGroupDialog: false,
      showDeleteMultipleGroupDialog: false,
      showAddStudentToGroupDialog: false,
      showSwitchStudentToGroupDialog: false,
      showDeleteOneChildMultiGroup: false,
      componentKey: 0,
    }
  },
  watch:{
    studentList:{
      handler(){
        this.$emit('set-loading')
      },
      deep:true
    }

  },
  props:{
    loadingData:{
      Type:Boolean,
      default:()=>false
    }
  },
  created() {
    this.$store.dispatch('studentStore/fetchDataKidsGroupList');
  },
  computed: {
    ...mapState('studentStore', ['kidsGroupList']),
    ...mapState('studentGroupModule', ['studentList', 'idKidList','dataSearch','totalPages']),
  },
  methods: {
    ...mapActions('studentStore', ['fetchDataKidsGroupList']),
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    handleAddStudent(row) {
      this.idKid = row.id
      if (row.listNameGroup.length === this.kidsGroupList.length) {
        this.$message({
          type: 'error',
          message: 'Học sinh này đã nằm ở tất cả các nhóm!'
        })
        return;
      }
      this.showAddStudentToGroupDialog = true
    },
    switchStudentToGroup(row) {
      this.idKid = row.id
      this.showSwitchStudentToGroupDialog = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      // eslint-disable-next-line no-unused-vars
      let multipleSelectionCopy = this.multipleSelection;
      let idKidList = [];
      multipleSelectionCopy = multipleSelectionCopy.map((item) => {
        idKidList = [...idKidList, item.id];
      });
      this.$store.commit("studentGroupModule/set_id_kid_list", idKidList);
    },
    handleCommand(command) {
      if (this.idKidList === "") {
        this.$message.error({
          showClose: true,
          message: "Chưa có học sinh nào được chọn",
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      switch (command) {
        case "addToGroup": {
          this.showAddToGroupDialog = true
          break;
        }
        case "switchGroup": {
          if (this.dataSearch.idGroup === ""){
            this.$message.error({
              showClose: true,
              message: "Bạn chưa chọn nhóm. Vui lòng chọn nhóm để thực hiện thao tác tiếp theo!",
              dangerouslyUseHTMLString: true,
            });
          }else {
            this.showSwitchGroupDialog = true
          }
          break;
        }
        case "deleteEachGroup": {
          if (this.dataSearch.idGroup === "") {
            this.$message.error({
              showClose: true,
              message: "Bạn chưa chọn nhóm. Vui lòng chọn nhóm để thực hiện thao tác tiếp theo!",
              dangerouslyUseHTMLString: true,
            });
          }else {
            this.showDeleteEachGroupDialog = true
          }
          break;
        }
        case "deleteMultipleGroup": {
          this.showDeleteMultipleGroupDialog = true
          break;
        }
      }
    },
    handleDelete(rowData) {
      this.showDeleteOneChildMultiGroup = true
      this.$refs.dialogDeleteOneKid.getDataDelete(rowData.id)
    },
    handleChange() {
      this.loadingTable = true
      // Vào store lấy danh sách học sinh theo filter
      this.$store.dispatch('studentGroupModule/filter_students_group', this.dataSearch)
    },
    // Thay đổi số phần tử trong trang
    handleSizeChange(value) {
      this.dataSearch.limit = value;
      this.handleChange();
    },
    // Thay đổi sang trang khác
    handleCurrentChange(value) {
      this.dataSearch.page = value;
      this.handleChange();
    },
    /**
     *  dialog field
     */
    closeAddToGroupDialog() {
      this.showAddToGroupDialog = false
    },
    closeSwitchGroupDialog() {
      this.showSwitchGroupDialog = false
    },
    closeDeleteEachGroupDialog() {
      this.showDeleteEachGroupDialog = false
    },
    closeDeleteMultipleGroupDialog() {
      this.showDeleteMultipleGroupDialog = false
    },
    closeAddStudentToGroupDialog() {
      this.showAddStudentToGroupDialog = false
    },
    closeSwitchStudentToGroupDialog() {
      this.showSwitchStudentToGroupDialog = false
    },
    closeDialogDeleteOneKid() {
      this.showDeleteOneChildMultiGroup = false
    },

  },

}
</script>
<style lang="scss" scoped>
.custom-scroll {
  max-height: 85px;
  //min-height: 70px;
  overflow: hidden;
  transition: overflow 0.3s ease-in-out;
  overflow-y: auto;
  //resize: vertical; /* Cho phép thay đổi kích thước cả chiều rộng và chiều cao */
}

</style>